import { UnorderedListOutlined, LogoutOutlined, MenuOutlined, PicLeftOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Modal, Spin, message } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { checkAuthState, loginWithGoogle, logout } from "../../firebase/auth";
import React, { useEffect, useState } from "react";
import useUserStore from "./userStore";
import googleSignInIcon from "../../assets/icons/google-signin-icon.png";
import i18next from "i18next";
import Flag from "react-flagkit";
import { useTranslation } from "react-i18next";

function isInWebView() {
  const ua = navigator.userAgent;
  return /FB_IAB|FBAN|Instagram|Line|Twitter/.test(ua);
}

interface Props {
  hideElement: boolean;
}

export default function GoogleAuthen({ hideElement }: Props) {
  const { userDetail, isFetching, setUserDetail, setIsFetching } = useUserStore();
  const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
  const { t } = useTranslation();
  const isInsideWebView = isInWebView();
  const [modalWarningWebView, setModalWarningWebView] = useState(false);

  let items = [
    {
      key: "1",
      label: (
        <Button
          onClick={() => {
            window.location.href = "/";
          }}
          icon={<HomeOutlined />}
          style={{ width: "100%", textAlign: "left" }}
        >
          หน้าแรก
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          icon={<UnorderedListOutlined />}
          style={{ width: "100%", textAlign: "left" }}
          onClick={() => {
            window.location.href = "/my-bills";
          }}
        >
          รายการบิล
        </Button>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          icon={<LogoutOutlined />}
          style={{ width: "100%", textAlign: "left" }}
          onClick={() => {
            logout();
          }}
        >
          ออกจากระบบ
        </Button>
      ),
    },
  ];

  if (userDetail?.email === "jaysorasak@gmail.com") {
    items.splice(2, 0, {
      key: "3",
      label: (
        <Button
          icon={<PicLeftOutlined />}
          style={{ width: "100%", textAlign: "left" }}
          onClick={() => {
            window.location.href = "/dashboard";
          }}
        >
          Dashboard
        </Button>
      ),
    });
  }

  function handleOpenInBrowser() {
    const currentURL = window.location.href; // get current URL
    const el = document.createElement("textarea");
    el.value = currentURL;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    message.success(t("successMessage.copyUrlComplete"));
  }

  useEffect(() => {
    checkAuthState((user) => {
      if (user) {
        setUserDetail(user);
      } else {
        setUserDetail(undefined);
      }
      setIsFetching(false);
    });
  }, [setUserDetail, setIsFetching]);

  useEffect(() => {
    if (userDetail) {
      setIsModalLoginOpen(false);
    }
  }, [userDetail]);

  function switchLanguage() {
    localStorage.setItem("language", i18next.language === "en" ? "th" : "en");
    const currentLang = i18next.language;
    const newLang = currentLang === "en" ? "th" : "en";
    i18next.changeLanguage(newLang);
  }

  return (
    <div className={`member-section ${hideElement ? "hide-element" : ""}`}>
      {isFetching ? (
        <Spin />
      ) : userDetail ? (
        <React.Fragment>
          <div
            className="wrap-powerby"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <div className="powerby">checkbillbyjsor.com</div>
          </div>
          <Dropdown menu={{ items }} placement="bottom" className="dropdown-usermenu" trigger={["click"]}>
            <Button className="user-button-icon" icon={<MenuOutlined />}>
              {userDetail.displayName?.split(" ")[0]}
            </Button>
          </Dropdown>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            className="wrap-powerby"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <div className="powerby">checkbillbyjsor.com</div>
          </div>
          <Button
            className="user-button-icon"
            onClick={() => {
              if (isInsideWebView) {
                setModalWarningWebView(true);
              } else {
                setIsModalLoginOpen(true);
              }
            }}
          >
            {t("signIn")}
          </Button>
        </React.Fragment>
      )}
      <Button className="language-switcher" onClick={switchLanguage}>
        {i18next.language === "th" ? <Flag country="TH" size={32} /> : <Flag country="US" size={32} />}
      </Button>
      <Modal
        centered
        onCancel={() => {
          setIsModalLoginOpen(false);
        }}
        className="modal-login"
        okButtonProps={{ style: { display: "none" } }}
        open={isModalLoginOpen}
        title={<div style={{ textAlign: "center" }}>{t("signInWarningMessage")}</div>}
      >
        <div className="wrap-signin-google">
          <Button
            className="google-signin-btn"
            onClick={loginWithGoogle}
            icon={<img src={googleSignInIcon} alt="google-signin" />}
          >
            {t("signInWithGoogle")}
          </Button>
        </div>
      </Modal>
      <Modal
        centered
        title={t("modalWarningOpenInWebview.title")}
        open={modalWarningWebView}
        onCancel={() => {
          setModalWarningWebView(false);
        }}
        maskClosable={false}
        footer={[
          <Button key="submit" type="primary" onClick={handleOpenInBrowser}>
            {t("modalWarningOpenInWebview.buttonCopyLink")}
          </Button>,
        ]}
      >
        <p>{t("modalWarningOpenInWebview.text")}</p>
      </Modal>
    </div>
  );
}
